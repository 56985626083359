<template>
  <div>
    <a-form-model :model="form">
      <a-row :gutter="16">
        <a-col :xs="24" :sm="12" :xl="4">
          <a-form-model-item prop="osType">
            <os-type-select v-model="form.osType"></os-type-select>
          </a-form-model-item>
        </a-col>
        <a-col :xs="24" :sm="12" :xl="4">
          <a-form-model-item prop="monitorAddress">
            <a-input v-model="form.monitorAddress" :allow-clear="true" :max-length="15" placeholder="请输入主机监控地址"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :xs="24" :sm="12" :xl="4">
          <a-form-model-item prop="name">
            <a-input v-model="form.name" :allow-clear="true" :max-length="128" placeholder="请输入主机名称"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :xs="24" :sm="12" :xl="4">
          <a-form-model-item>
            <a-button icon="search" type="primary" @click="search">查询</a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>

    <a-card :bordered="false">
      <a-table
        :columns="columns"
        :data-source="dataSource"
        :loading="loading"
        :pagination="false"
        row-key="id"
        :row-selection="rowSelection"
        :scroll="{ x: scrollX }"
        @change="change"
        style="margin-bottom: 16px;"
      >
        <template slot="name" slot-scope="record">
          <a-tooltip placement="topLeft" :title="record.name">{{ record.name }}</a-tooltip>
        </template>
        <template slot="os" slot-scope="record">
          <a-tooltip :title="record.os_version">
            <a-tag color="blue">{{ $t(`os_type.${record.os_type}`) }}</a-tag>
          </a-tooltip>
        </template>
        <template slot="spec" slot-scope="record">
          <a-space v-if="record.cpu || record.memory">
            <span v-if="record.cpu">{{record.cpu }}C </span>
            <span v-if="record.memory">{{ record.memory }}G</span>
          </a-space>
          <span v-else>-</span>
        </template>
        <template slot="projects" slot-scope="record">
          <a-tooltip v-if="record.length" placement="topLeft">
            <template slot="title">
              {{ record | ProjectNames }}
            </template>
            {{ record | ProjectNames }}
          </a-tooltip>
          <span v-else>-</span>
        </template>
        <template slot="monitored" slot-scope="record">
          <boolean-status-badge :value="record"></boolean-status-badge>
        </template>
      </a-table>

      <div style="height: 32px;">
        <a-pagination
          v-model="form.page"
          :pageSize="form.pageSize"
          :show-size-changer="true"
          :show-total="t => `共 ${t} 条`"
          :total="total"
          @change="page => { this.form.page = page; this.fetch() }"
          @showSizeChange="(_, pageSize) => { this.form.page = 1; this.form.pageSize = pageSize; this.fetch() }"
          style="float: right;"
        ></a-pagination>
      </div>
    </a-card>
  </div>
</template>

<script>
import { getHostList } from '@/api/host'
import BooleanStatusBadge from '@/components/badge/BooleanStatusBadge'
import OsTypeSelect from '@/components/select/OsTypeSelect'

export default {
  name: 'HostList',
  components: {
    BooleanStatusBadge,
    OsTypeSelect
  },
  data () {
    return {
      form: {
        project: undefined,
        monitorAddress: undefined,
        name: undefined,
        page: 1,
        pageSize: 10
      },
      columns: [
        { title: '主机名称', width: 200, ellipsis: true, scopedSlots: { customRender: 'name' } },
        { title: '操作系统', width: 160, scopedSlots: { customRender: 'os' } },
        { dataIndex: 'monitor_address', width: 160, title: '监控地址' },
        { title: '配置', width: 160, scopedSlots: { customRender: 'spec' } },
        { dataIndex: 'projects', title: '所属系统', width: 200, ellipsis: true, scopedSlots: { customRender: 'projects' } },
        {
          dataIndex: 'monitored',
          title: '监控状态',
          width: 160,
          fixed: 'right',
          scopedSlots: { customRender: 'monitored' },
          defaultFilteredValue: [],
          filters: [
            { text: '监控中', value: 'true' },
            { text: '未监控', value: 'false' }
          ],
          filterMultiple: false
        }
      ],
      dataSource: [],
      total: 0,
      loading: false,
      rowSelection: {
        selectedRowKeys: [],
        onChange: selectedRowKeys => {
          this.rowSelection.selectedRowKeys = selectedRowKeys
        }
      }
    }
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach(column => { sum += column.width })
      return sum
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      const params = {
        page: this.form.page,
        page_size: this.form.pageSize
      }
      if (this.form.project) params.project_id = this.form.project.key
      if (this.form.osType) params.os_type = this.form.osType
      if (this.form.hostType) params.host_type = this.form.hostType
      if (this.form.monitorAddress) params.monitor_address = this.form.monitorAddress
      if (this.form.name) params.name = this.form.name
      if (this.form.monitored) params.monitored = this.form.monitored
      this.loading = true
      getHostList(params).then(res => {
        const data = res.data
        this.dataSource = data.data
        this.total = data.total
      }).finally(() => {
        this.loading = false
      })
    },
    search () {
      this.form.page = 1
      this.rowSelection.selectedRowKeys = []
      this.fetch()
    },
    change (_, filters) {
      this.form.monitored = filters.monitored[0]
      this.fetch()
    }
  },
  filters: {
    ProjectNames: (list) => {
      let str = ''
      for (let i = 0; i < list.length; i++) {
        str += list[i].name
        if (i < list.length - 1) str += ','
      }
      return str
    }
  }
}
</script>
